// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akcja-sprzatanie-vol-3-js": () => import("./../../../src/pages/akcja-sprzatanie-vol-3.js" /* webpackChunkName: "component---src-pages-akcja-sprzatanie-vol-3-js" */),
  "component---src-pages-cleaning-action-rules-js": () => import("./../../../src/pages/cleaning-action-rules.js" /* webpackChunkName: "component---src-pages-cleaning-action-rules-js" */),
  "component---src-pages-contact-sukces-index-js": () => import("./../../../src/pages/contact/sukces/index.js" /* webpackChunkName: "component---src-pages-contact-sukces-index-js" */),
  "component---src-pages-email-js": () => import("./../../../src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-order-book-calendars-js": () => import("./../../../src/pages/order/book-calendars.js" /* webpackChunkName: "component---src-pages-order-book-calendars-js" */),
  "component---src-pages-order-branding-js": () => import("./../../../src/pages/order/branding.js" /* webpackChunkName: "component---src-pages-order-branding-js" */),
  "component---src-pages-order-corporate-calendars-js": () => import("./../../../src/pages/order/corporate-calendars.js" /* webpackChunkName: "component---src-pages-order-corporate-calendars-js" */),
  "component---src-pages-order-covid-19-items-js": () => import("./../../../src/pages/order/covid-19-items.js" /* webpackChunkName: "component---src-pages-order-covid-19-items-js" */),
  "component---src-pages-order-eco-friendly-gadgets-js": () => import("./../../../src/pages/order/eco-friendly-gadgets.js" /* webpackChunkName: "component---src-pages-order-eco-friendly-gadgets-js" */),
  "component---src-pages-order-exhibition-systems-js": () => import("./../../../src/pages/order/exhibition-systems.js" /* webpackChunkName: "component---src-pages-order-exhibition-systems-js" */),
  "component---src-pages-order-graphic-design-js": () => import("./../../../src/pages/order/graphic-design.js" /* webpackChunkName: "component---src-pages-order-graphic-design-js" */),
  "component---src-pages-order-outdoor-advertising-js": () => import("./../../../src/pages/order/outdoor-advertising.js" /* webpackChunkName: "component---src-pages-order-outdoor-advertising-js" */),
  "component---src-pages-order-packages-js": () => import("./../../../src/pages/order/packages.js" /* webpackChunkName: "component---src-pages-order-packages-js" */),
  "component---src-pages-order-photo-session-js": () => import("./../../../src/pages/order/photo-session.js" /* webpackChunkName: "component---src-pages-order-photo-session-js" */),
  "component---src-pages-order-promotional-prints-js": () => import("./../../../src/pages/order/promotional-prints.js" /* webpackChunkName: "component---src-pages-order-promotional-prints-js" */),
  "component---src-pages-order-promotional-video-js": () => import("./../../../src/pages/order/promotional-video.js" /* webpackChunkName: "component---src-pages-order-promotional-video-js" */),
  "component---src-pages-order-virtual-reality-js": () => import("./../../../src/pages/order/virtual-reality.js" /* webpackChunkName: "component---src-pages-order-virtual-reality-js" */),
  "component---src-pages-order-web-pages-js": () => import("./../../../src/pages/order/web-pages.js" /* webpackChunkName: "component---src-pages-order-web-pages-js" */),
  "component---src-pages-quote-art-open-js": () => import("./../../../src/pages/quote-art-open.js" /* webpackChunkName: "component---src-pages-quote-art-open-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-catalogs-page-js": () => import("./../../../src/templates/catalogs-page.js" /* webpackChunkName: "component---src-templates-catalogs-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-kreator-page-js": () => import("./../../../src/templates/kreator-page.js" /* webpackChunkName: "component---src-templates-kreator-page-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-offer-page-js": () => import("./../../../src/templates/offer-page.js" /* webpackChunkName: "component---src-templates-offer-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policy-page.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-portfolio-js": () => import("./../../../src/templates/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-js" */),
  "component---src-templates-suboffer-page-js": () => import("./../../../src/templates/suboffer-page.js" /* webpackChunkName: "component---src-templates-suboffer-page-js" */),
  "component---src-templates-tagi-js": () => import("./../../../src/templates/tagi.js" /* webpackChunkName: "component---src-templates-tagi-js" */),
  "component---src-templates-work-page-js": () => import("./../../../src/templates/work-page.js" /* webpackChunkName: "component---src-templates-work-page-js" */)
}

