module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"removeAccents":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#23C495","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Art Open 🌱","short_name":"Advertising agency | Art Open 🌳 covid-19, ecology, gadgets, branding, websites and shops, e-commerce, graphic design, VR and 3D, calendars.","start_url":"/index.html","background_color":"#ffffff","theme_color":"#23C495","display":"standalone","icons":[{"src":"/icons/icon-192-192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/icon-512-512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"none","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","stylesPath":"/opt/build/repo/src/assets/sass/styles.sass","enableIdentityWidget":true,"htmlTitle":"ArtOpen - Content Manager"},
    }]
